/* global site */

// Import site modules
import ('./site/scroll-animations.js');
import ('./site/charts.js');

window.site = (window.site || {});

/**
 * Main application class.
 * @class App
 * @static
 */
window.site.App = (function App() {
  /**
   * Has the class been initialized?
   * @private
   */
  let inited = false;

  /**
   * Application config defaults.
   * @private
   * @param config.env     Current server environment
   * @param config.csrf    Security token to submit with forms
   * @param config.csrfName    Security token to submit with forms
   * @param config.locale  Current locale short code
   * @param config.device  Device detection based on browser signature
   * @param config.preview Page is shown through live preview mode
   * @param config.general Settings from general config
   */
  const config = {
    env: 'production',
    csrf: null,
    csrfName: null,
    locale: 'en',
    device: 'desktop',
    preview: false,
    general: {},
  };

  /**
   * Initializes the class.
   * @public
   */
  const init = function init(options) {
    // Abort if already initialized
    if (inited) {
      return false;
    }

    inited = true;

    // Store application settings
    options = (options || {});

    if (options.env) { config.env = options.env; }
    if (options.csrf) { config.csrf = options.csrf; }
    if (options.csrfName) { config.csrfName = options.csrfName; }
    if (options.locale) { config.locale = options.locale; }
    if (options.device) { config.device = options.device; }
    if (options.preview) { config.preview = options.preview; }
    if (options.general) { config.general = options.general; }

    // Global
    // Detect css-grid un-supported
    const el = document.createElement("div")
    const supportsGrid = "string" == typeof el.style.grid;

    if(!supportsGrid) {
      const body = document.getElementsByTagName("body")[0].classList.add('is-ie-11');
    } else {
      // IE 11 || Old Browser - Ignore zone
      console.table(options);

      // Register ServiceWorker
      // if ('serviceWorker' in navigator) {
      //   console.log('Registering ServiceWorker...');
      //   window.addEventListener('load', function() {
      //       navigator.serviceWorker.register('/sw.js', {
      //           scope: "/"
      //       }).then(function(registration) {
      //           // Registration was successful
      //           console.log('ServiceWorker registration successful with scope: ', registration.scope);
      //           // Trim the caches on load
      //           navigator.serviceWorker.controller && navigator.serviceWorker.controller.postMessage({
      //               command: "trimCaches"
      //           });
      //      }).catch(function(err) {
      //           // registration failed :(
      //           console.log('ServiceWorker registration failed: ', err);
      //       });
      //   });
      // }

      // PreRender for fast website
      function prerenderlink(e) {
        console.log(e, e.currentTarget, e.currentTarget.href);
        var head = document.getElementsByTagName("head")[0];
        var refs = head.childNodes;
        var ref = refs[ refs.length - 1];

        var elements = head.getElementsByTagName("link");
        Array.prototype.forEach.call(elements, function(el, i) {
          if (("rel" in el) && (el.rel === "prerender"))
            el.parentNode.removeChild(el);
        });

        var prerenderTag = document.createElement("link");
        prerenderTag.rel = "prerender";
        prerenderTag.href = e.currentTarget.href;
        ref.parentNode.insertBefore(prerenderTag,  ref);
      }

      const $prerenderLinks = document.querySelectorAll('#page-header a');
      [].slice.call($prerenderLinks).forEach(function(elem) {
        elem.addEventListener('mouseenter', prerenderlink);
      });
    }

    //Vidéo
    var lightboxVideo = GLightbox({
        selector: '.glightbox3'
    });

    //Card Flip
    [].slice.call(document.querySelectorAll(".card")).forEach(function(elem) {
      elem.addEventListener('click', function(e) {
        elem.classList.toggle("is-flipped");
      });
    });

    //Menu
    document.querySelectorAll(".menu").forEach(i => i.addEventListener("click", e => {
        var body = document.querySelector('body');
        body.classList.add("active");

        var nav = document.querySelector('.main-header');
        nav.classList.add("active");

        var wrap = document.querySelector('.main-wrap');
        wrap.classList.add("active");

    }));

    document.querySelectorAll(".close").forEach(i => i.addEventListener("click", e => {
        var body = document.querySelector('body');
        body.classList.remove("active");

        var nav = document.querySelector('.main-header');
        nav.classList.remove("active");

        var wrap = document.querySelector('.main-wrap');
        wrap.classList.remove("active");

    }));

    document.querySelectorAll(".main-nav a").forEach(i => i.addEventListener("click", e => {
        var body = document.querySelector('body');
        body.classList.remove("active");

        var nav = document.querySelector('.main-header');
        nav.classList.remove("active");

        var wrap = document.querySelector('.main-wrap');
        wrap.classList.remove("active");

    }));

    //Pop Up
    function openpopup() {
      var overlay = document.querySelector('.popup-wrap');
      overlay.classList.add("show");
    }

    function closepopup() {
        var overlay = document.querySelector('.popup-wrap');
        overlay.classList.remove("show");
    }

    document.querySelector('[data-close-popup]').addEventListener('click', function(e) {
      closepopup()
    });

    [].slice.call(document.querySelectorAll('[data-open-popup]')).forEach(function(elem) {
      elem.addEventListener('click', function(e) {
        var id = elem.getAttribute('data-open-popup');
        document.querySelector('[data-popup] .content').innerHTML = document.querySelector(`[data-popup-content="${id}"]`).innerHTML;
        openpopup();
      });
    });

    document.querySelectorAll(".popup-wrap .opacity").forEach(i => i.addEventListener("click", e => {
      var overlay = document.querySelector('.popup-wrap');
      overlay.classList.remove("show");
    }));

    //Mobile menu
    function openmobilemenu() {
        var mobile = document.querySelector('.mobile-header');
        mobile.classList.add("open");
    }

    function closemobilemenu() {
      var mobile = document.querySelector('.mobile-header');
      mobile.classList.remove("open");
    }

    document.querySelector('.mobile-burger').addEventListener('click', function(e) {
      openmobilemenu();
    });

    document.querySelector('.close-menu').addEventListener('click', function(e) {
      closemobilemenu();
    });

    setTimeout(function(){
      [].slice.call(document.querySelectorAll('[data-enable-flickity]')).forEach(function(elem) {
        var flkOptions = {
          "contain": true,
          "draggable": true,
          "prevNextButtons": false,
          "wrapAround": true,
          "pageDots": false,
          "autoPlay": false,
          "imagesLoaded": true,
          "lazyLoad": true
        }

        var flkty = new Flickity( elem, flkOptions);
        var arrowsWrap = elem.closest('.arrows-wrap');

        if (arrowsWrap) {
          arrowsWrap.querySelector('.arrow-left').addEventListener('click', function(e) {
            flkty.previous();
          });
          arrowsWrap.querySelector('.arrow-right').addEventListener('click', function(e) {
            flkty.next();
          });
        }
      });
    }, 1235);

    // Return success
    return true;
  };

  /**
   * Getter for application config.
   * @public
   */
  const getConfig = function getConfig(option) {
    return config[option] ? config[option] : false;
  };

  /**
   * Expose public methods & properties.
   */
  return {
    init,
    config: getConfig,
  };
}());
